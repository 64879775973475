<template>
  <div class="container" id="content">
    <div class="main">
      <div class="list">
        <div style="font-size:2vh;font-weight: bold;margin:10px 0">水肥机列表</div>
        <ul class="ulCon">
          <li class="liCon" v-for="(item,index) in waterList" :key="item.id" isActive="isActive"
              @click="liClick(index,item)"
              :class="{active:currentIndex==index}">{{ item.name }}
          </li>
        </ul>
      </div>
      <div class="rightCon">
        <p style="margin-left: 1vh; font-size: 1.6vh; color: #333; font-weight: 600; margin-top: 1vh;">传感器列表</p>
        <div class="deviceList">
          <div v-if="deviceList.length > 0">
            <div class="deviceWrap" v-for="(item, index) in deviceList" :key="index">
              <div class="deviceItem">设备编号({{ item.deviceNumber }})</div>
              <div class="dataBox">
                <el-button style="font-size: 1vh" type="success" size="small" v-for="(item, index) in item.typeDataList" :key="index">
                  {{ item.name }}:{{ item.value }}
                </el-button>
              </div>
            </div>
          </div>
          <div v-else>
            <p style="width: 100%; text-align: center; margin-top: 10vh; font-weight: 600;">暂时没有绑定传感器设备</p>
          </div>
        </div>
        <div class="table">
          <div class="listHeader">
            <p style="margin-left: 1vh; font-size: 1.6vh; color: #333; font-weight: 600">继电器列表</p>
            <!-- <div>
              <el-button style="margin-right: 10px" class="all allClose" @click="changeState('p', 0)">批量关闭
              </el-button>
              <el-button class="all allNo" @click="changeState('p', 1)">批量开启</el-button>
            </div> -->
          </div>
          <el-table :data="tableData" style="width: 100%; margin-top: 1vh;">
            <el-table-column align="center" type="expand">
              <!--              v-for="(item, index) in tableList" :key="index"-->
              <template slot-scope="scope">
                <el-table ref="multipleTable" :data="scope.row.tableList" @selection-change="handleSelectionChange()">
                  <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                  <!--                  <el-table-column prop="id" label="序号"></el-table-column>-->
                  <el-table-column prop="sensorName" label="节点名称"></el-table-column>
                  <el-table-column prop="sensorCode" label="节点编号"></el-table-column>
                  <el-table-column label="操作" align="center" width="200" prop="state">
                    <template slot-scope="scope">
                      <el-switch active-color="#13ce66" :active-value="1" :inactive-value="0" inactive-color="#ff4949"
                                 v-model="scope.row.state" @change="changeState(scope.row.sensorCode, scope.row.state)">
                      </el-switch>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <!-- <el-table-column type="selection" width="55"></el-table-column> -->
            <el-table-column prop="id" label="序号" align="center"></el-table-column>
            <el-table-column prop="name" label="设备名称"></el-table-column>
            <el-table-column prop="deviceNumber" label="设备编号"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "remoteControl",
  data() {
    return {
      phData: "",
      ecData: "",
      currentIndex: 0,
      waterList: [{name: ''}],
      typeDataList: [{}],
      tableData: [],
      datas: [],
      sensorId: '',
      listItem: '',
      rightConData: "",
      deviceList: [],
      sensorCodes: '',
      deviceNumber: '',
      tableList: [{sensorName: "", sensorCode: ""}]
    }
  },
  mounted() {
    // this.getEcharts(0)
    this.getLists()
  },
  methods: {
    // 获取水肥机列表
    getLists() {
      this.$get("/deviceManage/getDevicesList", {
        page: 1,
        limit: 100,
        name: '',
      }).then((res) => {
        if (res.data.state == "success") {
          //console.log('res.data', res.data)
          if (res.data.datas) {
            let newData = [];
            for (let i = 0; i < res.data.datas.length; i++) {
              let datas = res.data.datas[i];
              if (datas.state == '1') {
                newData.push(datas)
                //console.log('newData', newData)
                this.waterList = newData;
                this.getDeviceList(res.data.datas[0].id);
                this.deviceNumber = res.data.datas[0].id;
                // for (let i = 0; i < this.waterList.length; i++) {
                //   let datai = this.waterList[i]
                // }
              }
            }
          }
        }
      });
    },
    //获取传感器列表
    getDeviceList(deviceNumber) {
      this.$get('/deviceManage/getAllDeviceSensorLists', {
        deviceId: deviceNumber
      }).then((res) => {
        if (res.data.state == 'success') {
          var sensorList = [];
          var relayList = [];
          var sensor = res.data.data.list;
          var relay = res.data.data.list1;
          // 继电器
          for (let i = 0; i < relay.length; i++) {
            var relayi = relay[i];
            //继电器1 / 继电器2 / 继电器3 / 继电器4
            if (relay[i].type) {
              let node = []
              var typeName = relay[i].type.replaceAll(/\s/g, "").split("/");
              var switchArr = relay[i].data.replaceAll(/\s/g, "").split("|")
              //console.log('typeName', typeName)
              //console.log('switchArr', switchArr)
              if (relay[i].sensorTemplateBeans) {
                var nodeNumber = [];
                for (let a = 0; a < relay[i].sensorTemplateBeans.length; a++) {
                  nodeNumber.push(relay[i].sensorTemplateBeans[a].sensorCode);
                }
                //console.log('节点编号', nodeNumber)
              }
              for (let y = 0; y < typeName.length; y++) {
                node.push({sensorName: typeName[y], sensorCode: nodeNumber[y]})
              }
              //console.log('节点列表', node)
              this.tableList = node
              for (let z = 0; z < switchArr.length; z++) {
                //console.log('this.tableList[z].state', this.tableList[z].state)
                //console.log('----', switchArr[z], switchArr[z] == '开')
                this.tableList[z].state = switchArr[z] == '开' ? 1 : 0
                //console.log('this.tableList[z].state', this.tableList[z].state)
              }
              relayi.tableList = node;
            }
            relayList.push(relayi);
          }
          //console.log('relayList', relayList)
          // 传感器
          for (let j = 0; j < sensor.length; j++) {
            sensorList.push(sensor[j])
          }
          //console.log('sensorList', sensorList)
          for (let x = 0; x < sensorList.length; x++) {
            if (sensorList[x].deviceBean) {
              let arr = [];
              if (sensorList[x].deviceBean.type || sensorList[x].deviceBean.data) {
                let dataArr = sensorList[x].deviceBean.data.split('|');
                let typeArr = sensorList[x].deviceBean.type.split('/');
                for (let k = 0; k < typeArr.length; k++) {
                  arr.push({name: typeArr[k], value: dataArr[k]})
                }
                sensorList[x].typeDataList = arr;
              }
            }
          }
          this.deviceList = sensorList;
          this.tableData = relayList;
          //console.log('this.tableData', this.tableData)
        }
      })
    },
    // 批量开启/关闭
    changeState(data, type) {
      //console.log("data", data)
      //console.log('type', type)
      let device = data.substring(0, 12)
      let end = data.charAt(data.length - 1)
      //console.log("device", device)
      //console.log("end", end)
      let value = 0 + end + 0 + type;
      //console.log("value", value)

      // type为0代表关闭1为开启
      this.$post('/relay/sendCommand', {
        // deviceType: 3,
        deviceNumber: device,
        command: value,
        // value:value
      }).then((res) => {
        if (res.data.state == 'success') {
          this.getDeviceList(this.deviceNumber);
        }
      })
    },

    //获取节点
    handleSelectionChange(e) {
      //console.log('展开了', e)
      this.sensorCodes = e;
    },
// 左侧水肥机列表点击事件
    liClick: function (index, item) {
      this.currentIndex = index;
      this.listItem = item;
      this.sensorId = item.id;
      this.deviceNumber = item.id;
      this.getDeviceList(item.id);
    },
    // 节点列表
    // getSensorList(idds) {
    //   this.tableData = [
    //     {
    //       id: 1,
    //       deviceNumber: '415645415152',
    //       children: [{ deviceNode: 'fdsafdasf4d5a'}, { deviceNode: 'fdsafdasf4d5a'}]
    //     },
    //     {
    //       id: 2,
    //       deviceNumber: '415645415152',
    //       children: [{ deviceNode: 'fdsafdasf4d5a'}, { deviceNode: 'fdsafdasf4d5a'}]
    //     }
    //   ]
    // }
    // 开关回调
    // test: function (e) {
    //   //console.log('开关回调', e)
    //   //console.log('e.id', e.id)
    //   //console.log('e.state', e.state)
    //   this.$post('/deviceManage/upDeviceSensorState', {
    //     id: e.id,
    //     state: e.state
    //   }).then((res) => {
    //     if (res.data.state == "success") {
    //       //console.log('开开开关关关')
    //       // this.getSensorList(idss)
    //     }
    //   })
    // },
    // 一键关闭
    // allClose(allItem, datas) {
    // 	//console.log('allItem', allItem)
    // 	//console.log('datas', datas)
    // 	let arr = [];
    // 	datas.forEach((i) => {
    // 		arr.push(i.id)
    // 	})
    // 	//console.log('arr', arr)
    // 	//console.log('arr', arr.toString())
    // 	this.$post('/deviceManage/upDeviceSensorsState', {
    // 		deviceId: allItem,
    // 		ids: arr.toString(),
    // 		state: 0
    // 	}).then((res) => {
    // 		if (res.data.state == 'success') {
    // 		//console.log('一键关闭了')
    // 		// //console.log(this.sensorId)
    // 		this.getSensorList(this.sensorId)
    // 		}
    // 	})
    // },
    // 一键开启
    // allNo(allItem, datas) {
    // 	let arr = [];
    // 	datas.forEach((i) => {
    // 		arr.push(i.id)
    // 	})
    // 	this.$post('/deviceManage/upDeviceSensorsState', {
    // 		deviceId: allItem,
    // 		ids: arr.toString(),
    // 		state: 1
    // 	}).then((res) => {
    // 		if (res.data.state == 'success') {
    // 		//console.log('一键开启了')
    // 		this.getSensorList(this.sensorId)
    // 		}
    // 	})
    // },
  }
}
</script>

<style scoped>
/*.btn /deep/ .el-button, .el-button--success {*/
/*  margin: 0 !important;*/
/*  !*background-color: transparent !important;*!*/
/*  border: 1px solid #85ce61 !important;*/
/*}*/
.main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.list {
  width: 14vw;
  max-height: 90vh;
  border-right: 1px solid #bbb;
  overflow-y: scroll;
}

.liCon:focus {
  background-color: #CDF5E7;
}

.ulCon {
  width: 100%;
}

.liCon {
  width: 96%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*margin-top: 1vh;*/
  padding: 1vh 3%;
  cursor: pointer;
  font-size: 2vh;
  line-height: 5vh;
  border-bottom: 1px solid #bbb;
}

.active {
  background-color: #CDF5E7;
}

.rightCon {
  width: 70vw;
  height: 92vh;
}

.switchBox {
  width: 96%;
  height: 33vh;
  padding: 1vh 1vw;
  display: flex;
  flex-direction: row;
  border: 2px solid;
  border-image: linear-gradient(0deg, #46EB88, #04CC89) 10 10;
  box-shadow: 0px 6px 18px 2px rgba(4, 0, 0, 0.15);
  border-radius: 8px;
  margin-left: 10px;
}

.table {
  width: 99%;
  height: 54vh;
  margin-left: 10px;
  overflow-y: scroll;
}

.each {
  width: 25%;
  height: 100%;
}

.flowNumber {
  width: 100%;
  height: 4vh;
  line-height: 4vh;
  font-size: 1.5vh;
  text-align: center;
}

.echarts {
  width: 100%;
  height: 28vh;
}

.listHeader {
  font-size: 2vh;
  /*font-weight: bold;*/
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.all {
  width: 5vw;
  height: 4vh !important;
  border: 1px solid #848484;
  cursor: pointer;
  line-height: 4vh !important;
  text-align: center !important;
  border-radius: 10px;
  color: #848484;
  padding: 0 !important;
}

.allClose:focus {
  color: #04CC89;
  border: 1px solid #04CC89;
}

.allNo:focus {
  color: #04CC89;
  border: 1px solid #04CC89;
}

.table /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #04CC89 !important;
  border-color: #04CC89 !important;
}


.deviceList {
  width: 100%;
  height: 32vh;
  padding: 1vh;
  box-sizing: border-box;
  overflow: auto;
}

.deviceWrap {
  width: 100%;
  height: 5vh;
  border: 1px solid #139660;
  border-radius: 8px;
  margin-top: 2vh;
  display: flex;
  flex-direction: row;
}

.deviceItem {
  display: inline-block;
  width: 23.5%;
  line-height: 5vh;
  padding-left: 1.5vh;
  box-sizing: border-box;
  color: #333;
}

.dataBox {
  width: 77%;
  height: 100%;
  /*white-space: nowrap;*/
  overflow-x: scroll;
}

.dataBox /deep/ .el-button {
  height: 90% !important;
  margin: 2px 3px;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.menu /deep/ .el-table__expanded-cell[class*='cell'] {
  padding: 0px 50px !important;
}

.menu /deep/ .el-table td,
.el-table th {
  padding: 1.11vh 0;
}

.menu .myPlus {
  cursor: pointer;
  margin-right: 3vh;
}

.menu /deep/ .el-dialog__title {
  font-size: 1.6vh;
}

.puplcForm /deep/ .el-form-item__label {
  font-size: 1.4vh;
}
</style>
